import React from 'react'
import { CardBox, CardBoxProps } from './CardBox'
import { CategoryTag, CategoryTagProps } from './CategoryTag'
import { Picture, PictureFit } from './Picture'
import { useTranslate } from '../src/hooks/useTranslate'
import { ImageFragment } from '../src/model/fragments'

export interface ArticleTileProps {
	title: CardBoxProps['title']
	lead: CardBoxProps['content']
	FlexiLinkProps: CardBoxProps['FlexiLinkProps']
	categoryTagText: CategoryTagProps['text'] | undefined
	image?: ImageFragment
}

export const ArticleTile: React.FunctionComponent<ArticleTileProps> = ({
	title,
	lead,
	FlexiLinkProps,
	categoryTagText,
	image,
}) => {

	const translate = useTranslate()

	return ( <section className="articleTile">
		<div className="articleTile-image">
			{image && (
				<Picture
					mode="cover"
					alt={title || ''}
					width={600}
					height={400}
					image={image}	
				/>
			)}
		</div>
		<div className="articleTile-in">
			<div className="articleTile-card">
				<div className="articleTile-card-in">
					<CardBox
						FlexiLinkProps={FlexiLinkProps}
						hrefText={translate('read-more')}
						title={title}
						//date="22.1.2020"
						content={lead}
						categoryTag={
							categoryTagText && <CategoryTag text={categoryTagText} />
						}
					/>
				</div>
			</div>
		</div>
	</section>);

}