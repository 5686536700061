import Link from 'next/link'
import React from 'react'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { usePageLink } from '../src/hooks/usePageLink'
import { useTranslate } from '../src/hooks/useTranslate'
import { ArticleFragment, TopicFragment, SpecialPageType } from '../src/model/fragments'
import { getLocale, getPossibleLocales } from '../src/model/locale'
import { getBlogArticleLink } from '../src/model/urls'
import { ArticleTile } from './ArticleTile'
import { Button } from './Button'

export interface ArticleListProps {
	showLinkToBlog?: boolean
	items: ArticleFragment[]
}

export const ArticleList: React.FunctionComponent<ArticleListProps> = ({
	showLinkToBlog,
	items,
}) => {
	const blogLink = usePageLink(SpecialPageType.blog)
	const locale = useCurrentLocale()
		const translate = useTranslate()
	const possibleLocales = getPossibleLocales(locale)
	
	return (
		<section className="articleList">
			{items.length === 0 ? (
				<h2>{translate('search-noMatch')}</h2>
			) : (
				<div className="articleList-items">
					{items.map((item, i) => {
						const data = getLocale(item.locales, [locale]);

						return data &&
							data.link  &&  (
								<div className="articleList-item" key={`${i}-${item.id}`}>
									<ArticleTile
										title={data.title}
										lead={data.lead}
										FlexiLinkProps={{
											nextLink: getBlogArticleLink(locale, item.id, data.link!.url),
										}}
										categoryTagText={(item.topics ?? [])
											.map((topic : TopicFragment) => {
												const tdata = getLocale(topic.locales, possibleLocales);
												return tdata ? tdata.title : '';
											})
											.filter(x => x)
											.join(', ')}
										image={item.tileImage}
									/>
								</div>
							)
						
					}) }
				</div>
			)}
			{showLinkToBlog && blogLink && (
				<div className="articleList-more">
					<Link {...blogLink}>
						<a className="articleList-more-link">
							<Button arrow>{translate('go-to-blog')}</Button>
						</a>
					</Link>
				</div>
			)}
		</section>
	)
}
