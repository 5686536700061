import cn from 'classnames'
import React from 'react'
import { ImageFragment } from '../src/model/fragments'
import { Picture } from './Picture'

export interface TextWithImageProps {
	image?: ImageFragment
	swap?: boolean
}

export const TextWithImage: React.FunctionComponent<TextWithImageProps> = ({
	children,
	image,
	swap = false,
}) => {
	return (
		<div className={cn('textWithImage', swap && 'view-swap')}>
			<div className="textWithImage-text">{children}</div>
			<div className="textWithImage-image">
				{image && (
					<Picture
						image={image}
						width={800}
						height={500}
						alt=""
					/>
				)}
			</div>
		</div>
	)
}
