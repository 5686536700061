import React from 'react'

const CareerPositionContext = React.createContext({
	isPosition: false,
	message: '',
})

export const CareerPositionContextProvider: React.FunctionComponent<{
	message: string
}> = ({ message, children }) => (
	<CareerPositionContext.Provider value={{ isPosition: true, message }}>
		{children}
	</CareerPositionContext.Provider>
)

export const useCareerPosition = () => React.useContext(CareerPositionContext)
