import React, { ReactNode } from 'react'

type ConfiguratorContextParams = {
	isConfigurator: boolean,
	staticMessageContent: () => string | null,
	subject: string
}

const ConfiguratorContext = React.createContext({
	isConfigurator: false,
	staticMessageContent: () => {},
	subject: ''
} as ConfiguratorContextParams)

export const ConfiguratorContextProvider: React.FunctionComponent<{
	staticMessageContent: () => string | null,
	subject: string
}> = ({ staticMessageContent, subject, children }) => (
	<ConfiguratorContext.Provider value={{ isConfigurator: true, subject, staticMessageContent }}>
		{children}
	</ConfiguratorContext.Provider>
)

export const useConfigurator = () => React.useContext(ConfiguratorContext)
