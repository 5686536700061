import cn from 'classnames'
import React from 'react'
import { ImageFragment } from '../src/model/fragments'
import { ConditionalWrap } from './ConditionalWrap'
import { Lightbox } from './Lightbox'
import { Picture } from './Picture'

export type GalleryGridItem = ImageFragment

export interface GalleryGridProps {
	items: GalleryGridItem[]
	viewQuestionOrCareerList?: boolean
	viewStretch?: boolean
	noCrop?: boolean
	noReveal?: boolean
}

export const GalleryGrid: React.FunctionComponent<GalleryGridProps> = ({
	items,
	viewQuestionOrCareerList = false,
	viewStretch = false,
	noCrop = false,
	noReveal = false,
}) => {
	const [lightboxIndex, setLightboxIndex] = React.useState(0)
	const [isOpen, setIsOpen] = React.useState(false)

	if(!items.length)
	{
		return null;
	}

	const [width, height, ratio] = React.useMemo(() => {

		let r = 340.0 / 240.0;
		if(noCrop && items[0].intrinsicWidth && items[0].intrinsicHeight)
		{
			r = items[0].intrinsicWidth / items[0].intrinsicHeight;
		}

		return [1230, 1230 / r, r];
	}, items);

	return (
		<div
			className={cn(
				'galleryGrid',
				viewQuestionOrCareerList &&
					viewStretch === false &&
					items.length === 1 &&
					'view-shrinkSingle',
				noCrop && 'view-noCrop',
			)}
			style={{
				['--galleryGrid-columns-count' as any]: `${
					items.length === 1
						? 1
						: items.length === 5
						? 5
						: items.length % 3 === 0
						? 3
						: viewQuestionOrCareerList && items.length % 4 === 0
						? 4
						: 2
				}`,
			}}
		>
			{items.map((item, i) => (
				<div
					className="galleryGrid-item"
					key={i}
					style={
						noCrop
							? {
									['--galleryGrid-aspect-ratio' as any]:
										ratio,
							  }
							: undefined
					}
				>
					<ConditionalWrap
						condition={!noReveal}
						wrap={(children) => (
							<button
								type="button"
								className="galleryGrid-item-button"
								onClick={() => {
									setLightboxIndex(i)
									setIsOpen(true)
								}}
							>
								{children}
							</button>
						)}
					>
						<div className="galleryGrid-item-in">
							<Picture
								image={item}
								width={width}
								height={height}
								alt=""
								mode={items.length > 1 ? 'fill' : 'contain'}
							/>
						</div>
					</ConditionalWrap>
				</div>
			))}
			{isOpen && (
				<Lightbox
					items={items.filter(it => it.previewUrl).map((item) => ({
						url: item.previewUrl!,
					}))}
					index={lightboxIndex}
					setIndex={setLightboxIndex}
					onCloseRequest={() => setIsOpen(false)}
				/>
			)}
		</div>
	)
}
