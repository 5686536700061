import cn from 'classnames'
import React from 'react'

export interface LeadTextProps {
	viewInsideIntro?: boolean
}

export const LeadText: React.FunctionComponent<LeadTextProps> = ({
	viewInsideIntro = false,
	children,
}) => {
	return (
		<div className={cn('leadText', viewInsideIntro && 'view-insideIntro')}>
			{children}
		</div>
	)
}
