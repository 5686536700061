import { Input } from './Input'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { axiosPost } from '../src/utils/axiosWrapper'

interface FileUploadInputProps {
	label: string
	name: string
	onStateChange: (state: FileUploadInputState) => void
}

export enum FileUploadInputStatus {
	empty,
	processing,
	success,
	error,
}
export interface FileUploadInputEmptyState {
	status: FileUploadInputStatus.empty
}

export interface FileUploadInputProcessingState {
	status: FileUploadInputStatus.processing
	progress: number
}
export interface FileUploadInputSuccessState {
	status: FileUploadInputStatus.success
	publicUrl: string
}

export interface FileUploadInputErrorState {
	status: FileUploadInputStatus.error
	errorMessage: string
}
export type FileUploadInputState =
	| FileUploadInputEmptyState
	| FileUploadInputProcessingState
	| FileUploadInputSuccessState
	| FileUploadInputErrorState

export const readToBase64 = (
	file: File,
): Promise<string | ArrayBuffer> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => {
			if (reader.result === null) {
				reject()
				return
			}

			const res = reader.result as string;
			const parts = res.split(',');
			resolve(parts[parts.length - 1])
		}
		reader.readAsDataURL(file)
	})

export const FileUploadInput: React.FC<FileUploadInputProps> = (props) => {
	const [state, setState] = useState<FileUploadInputState>({
		status: FileUploadInputStatus.empty,
	})
	useEffect(() => {
		props.onStateChange(state)
	}, [props, state])
	const onUpload = useCallback(async (e: ChangeEvent) => {
		const file = (e.target as HTMLInputElement).files?.[0]
		if (!file) {
			return
		}

		setState({ status: FileUploadInputStatus.processing, progress: 0 })
		try {
			const payload = {
				filename: file.name,
				content: await readToBase64(file)
			};

			const response = await axiosPost('/documents/upload', {}, payload, (loaded: number, total: number) => {
				setState({
					status: FileUploadInputStatus.processing,
					progress: total == 0 ? 0 : loaded / total,
				})
			});

			setState({
				status: FileUploadInputStatus.success,
				publicUrl: response.publicUrl,
			})
		} catch (e) {
			console.error(e)
			setState({
				status: FileUploadInputStatus.error,
				errorMessage: 'Upload failed',
			})
		}
	}, [])

	const labelSuffix = (() => {
		switch (state.status) {
			case FileUploadInputStatus.processing:
				return ` (nahrávám: ${Math.round(state.progress * 100)}%)`
			case FileUploadInputStatus.success:
				return ' (nahráno)'
			case FileUploadInputStatus.error:
				return ': Došlo k chybě při nahrávání'
		}
		return ''
	})()

	return (
		<Input
			label={props.label + labelSuffix}
			name={props.name}
			type="file"
			onChange={onUpload}
			// ref={register}
		/>
	)
}
