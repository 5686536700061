import cn from 'classnames'
import React from 'react'
import {
	BannerFragment,
	ContentBlockFragment,
	ImageFragment,
	LinkFragment,
} from '../src/model/fragments'
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { CardView } from './Card'
import { CardBox } from './CardBox'
import { FlexiLink, FlexiLinkProps } from './FlexiLink'
import { Picture, PictureEncode, PictureFit } from './Picture'
import { Swiper as SwiperLibrary, SwiperSlide } from 'swiper/react'

export interface InteractiveHeroProps {
	banners: BannerFragment[]
}

export const InteractiveHeroInner: React.FunctionComponent<BannerFragment & {first: boolean}> = ({
	image,
	link,
	items,
	first
}) => {

	const w = image.intrinsicWidth ?? 2560;
	const h = image.intrinsicHeight ?? 650;

	let width = Math.min(w, 1920);
	let height = Math.min(700, width / (w / h));

	return (
		<div >
			<div className="interactiveHero-picture" style={{minHeight: height }}>
				<Picture
					alt=""
					width={width}
					height={height}
					image={image}
					layout={PictureFit.FitOriented}
					preload={first}
					encode={PictureEncode.jpg}
					mode={'contain'}
				/>
			</div>
			<div className="interactiveHero-in">
				{/* Variant with multiple small points of interest */}
				{items && items.map(
					(item, i) =>
						item.link && (
							<div
								key={i}
								className="interactiveHero-pointOfInterest"
								style={{
									['--interactiveHero-x' as any]: item.xPosition,
									['--interactiveHero-y' as any]: item.yPosition,
								}}
							>
								<FlexiLink {...(item.link as FlexiLinkProps)}>
									<a className="interactiveHero-pointOfInterest-button">
										<div
											className={cn(
												'interactiveHero-pointOfInterest-label',
												`view-position-${item.labelPosition}`,
											)}
										>
											<CardBox title={item.label} view={CardView.small} />
										</div>
									</a>
								</FlexiLink>
							</div>
						),
				)}

				{/* Variant with a single link from the entire banner */}
				{link && <div
					className="interactiveHero-fullPageLink"
				>
					<FlexiLink {...(link as FlexiLinkProps)}>
						<a>

						</a>
					</FlexiLink>
				</div> }
				
			</div>
		</div>
	)
}

export const InteractiveHero: React.FunctionComponent<InteractiveHeroProps> = ({
	banners
}) => {

	if(banners.length > 1)
	{
		SwiperCore.use([Autoplay]);
	}

	return <div className="interactiveHero">
		<SwiperLibrary
			slidesPerView={1}
			loop={ true }
			effect={'fade'}
			autoplay={ banners.length > 1 ? {
				delay: 5000,
				pauseOnMouseEnter: true,
				disableOnInteraction: false
			} : false}
			centerInsufficientSlides
		>
			{banners.map((banner, i) => (
				<SwiperSlide key={i}>
					<InteractiveHeroInner 
						first={i == 0}
						link={banner.link}
						image={banner.image}
						items={banner.items}
					/>
				</SwiperSlide>
			))}
		</SwiperLibrary>
	</div>
}

