import { contentToPlainText } from '../src/model/content'
import { ContentFragment, LocaleSimpleFragment, ProductLocaleFragment } from '../src/model/fragments'
import { Locale } from '../src/model/locale'
import { tuple } from '../src/utils/tuple'

export const getContentMap = <
	T extends { locale?: { code: string }; content?: ContentFragment },
>(
	locales: T[],
): Record<string, string> => {
	return Object.fromEntries(
		locales.map(({ locale, content }) =>
			tuple(locale?.code || '', content ? contentToPlainText(content) : ''),
		),
	)
}
export const fillMissingSk = <T extends LocaleSimpleFragment>(
	locales: T[],
): T[] => {
	const cs = locales.find((it) => it.locale?.code === Locale.cs)
	if (!cs) {
		return locales
	}
	if (!locales.find((it) => it.locale?.code === Locale.sk)) {
		return [...locales, { ...cs, locale: { code: Locale.sk } }]
	}
	return locales.map((it) =>
		it.locale?.code !== Locale.sk ? it : { ...cs, ...it },
	)
}

export const dateToDMY = (date: string) => {
	const d = new Date(date)
	return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()}`
}
