import * as runtypes from 'runtypes'
import { apiFetch } from './helpers'

export const SendContactMessageRequest = runtypes
	.Record({
		type: runtypes.Union(
			runtypes.Literal('career'),
			runtypes.Literal('contact'),
			runtypes.Literal('question'),
			runtypes.Literal('project'),
			runtypes.Literal('configurator'),
		),
		email: runtypes.String,
		phone: runtypes.String,
		name: runtypes.String,
		message: runtypes.String,
		recaptcha: runtypes.String,
	})
	.And(
		runtypes.Partial({
			place: runtypes.String,
			city: runtypes.String,
			zip: runtypes.String,
			fileUrl: runtypes.String,
			providedSubject: runtypes.Union(runtypes.String, runtypes.Undefined),
			providedEmail: runtypes.Union(runtypes.String, runtypes.Undefined),
			staticMessageContent: runtypes.Union(runtypes.String, runtypes.Undefined),
			attachmentUrl: runtypes.String,
			token: runtypes.String,
		}),
	)

export type SendContactMessageRequest = runtypes.Static<
	typeof SendContactMessageRequest
>

export const SendContactMessageResponseData = runtypes.Null
export type SendContactMessageResponse = runtypes.Static<
	typeof SendContactMessageResponseData
>

export const sendContactMessage = async (values: SendContactMessageRequest) => {
	return await apiFetch(
		SendContactMessageResponseData,
		'/api/sendContactMessage',
		{
			...values,
			staticMessageContent: (typeof values.staticMessageContent === 'function') ? (values.staticMessageContent as unknown as () => string)() :  values.staticMessageContent,
			place: values.place || '',
		},
	)
}
