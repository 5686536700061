import React, { MutableRefObject, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';


// https://medium.com/@pal.amittras/lazy-loading-react-components-on-page-scroll-using-intersection-observer-api-hook-and-wrapper-1a81e4cf1325
interface ViewportWrapProps {
    children: JSX.Element;
    threshold?: number;
    minHeight: number;
    forceShowHeight?: number;
}

export const ViewportWrap: React.FunctionComponent<ViewportWrapProps> =
({ children, minHeight, forceShowHeight,  threshold = 0.05}) => {
    const { ref, inView, entry } = useInView({threshold: threshold});
    const [shown, setShown] = React.useState(false);

    React.useEffect(() => {
        if(!shown && inView)
        {
            //console.log('showing ' + forceShowHeight);
            setShown(true);
        }
    }, [inView]);


    React.useEffect(() => {
        // Here we set up handler to react to the first scroll location after going back/forward
        if(forceShowHeight !== undefined)
        {
            const listener = () => {

                if(window.scrollY > forceShowHeight)
                {
                    //console.log('forcingShow ' + forceShowHeight);
                    setShown(true);
                }
    
                window.removeEventListener('scroll', listener);
            };
    
            window.addEventListener('scroll', listener);
        }
    }, []);

    return <>{ children }</>;
    return <div style={shown ? {} : { minHeight: minHeight}} ref={ref}>
        {
            shown && 
            <Suspense fallback={<section></section>}>
                { children }
            </Suspense>
        }
    </div>

}