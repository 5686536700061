import React from 'react'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { CategoryListItemLocaleFragment, ContentCategoryListItemFragment, LinkType } from '../src/model/fragments'
import { CategoryImage } from './CategoryImage'

export interface CategoryListProps {
	items: ContentCategoryListItemFragment[]
}

export const CategoryList: React.FunctionComponent<CategoryListProps> = ({
	items,
}) => {
	const localization = useLocalization()

	return (
		<section className="categoryList">
			<div className="categoryList-items">
				{items.map((category, i) => (
					<div className="categoryList-item" key={i}>
						<CategoryImage
							key={category.id}
							noRadius
							FlexiLinkProps={{
								type: LinkType.internal,
								internalLink: category.link!,
							}}
							image={category.tileImage}
							title={localization.extractLocale<CategoryListItemLocaleFragment>(category.locales)?.name || ''}
						/>
					</div>
				))}
			</div>
		</section>
	)
}
