import cn from 'classnames'
import React from 'react'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { usePossibleLocales } from '../src/hooks/usePossibleLocales'
import { ContentBlockColumnListItemFragment, ContentBlockFragment as ContentBlockFragmentType, ContentCategoryListItemFragment, ImageFragment } from '../src/model/fragments'
import { getLocale, Locale } from '../src/model/locale'
import { getProductCategoryLink } from '../src/model/urls'
import { assertNever } from '../src/utils/assertNever'
import { isDefined } from '../src/utils/isDefined'
import { ArticleList } from './ArticleList'
import { BarelyVisibleTitle } from './BarelyVisibleTitle'
import { CategoriesSwiper } from './CategoriesSwiper'
import { CategoryImage } from './CategoryImage'
import { CategoryList } from './CategoryList'
import { ContactForm } from './ContactForm'
import { Contained } from './Contained'
import { FlexiLinkProps } from './FlexiLink'
import { Documents } from './Documents'
import { GalleryGrid } from './GalleryGrid'
import { Garden } from './Garden'
import { HighlightedBox } from './HighlightedBox'
import { InteractiveHero } from './InteractiveHero'
import { HomepageTilesGrid, HomepageTilesGridItem } from './HomepageTilesGrid'
import { LeadText } from './LeadText'
import { LinkBoxes } from './LinkBoxes'
import { LinkSlider } from './LinkSlider'
import { News } from './News'
import { NumberedColumns } from './NumberedColumns'
import { ProductList } from './ProductList'
import { Share, ShareProps } from './Share'
import { Spacer } from './Spacer'
import { TextColumns } from './TextColumns'
import { TextWithImage } from './TextWithImage'
import { TopProducts } from './TopProducts'
import { WidthBound } from './WidthBound'
import { Wysiwyg } from './Wysiwyg'
import { VideoArticle } from './VideoArticle'
import { ContentBlockType } from '../src/model/content'
import { ViewportWrap } from './ViewportWrap'
import { VideoTiles } from './VideoTiles'

export interface ContentProps {
	content: { blocks: ContentBlockFragmentType[] } | undefined
	viewQuestionOrCareerList?: boolean
	viewInsideIntro?: boolean
	innerSize?: undefined | 'narrow' | 'wide' | 'normal'
	share?: ShareProps['data']
}

function renderBlock(
	block: ContentBlockFragmentType,
	locale: Locale,
	possibleLocales: Locale[],
	viewQuestionOrCareerList: boolean,
	viewInsideIntro: boolean,
	index = 0,
) {
	if (block.type === ContentBlockType.text) {
		return (
			<Wysiwyg>
				<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
			</Wysiwyg>
		)
	}
	if (block.type === ContentBlockType.leadText) {
		return (
			<LeadText viewInsideIntro={viewInsideIntro}>
				<Wysiwyg>
					<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
				</Wysiwyg>
			</LeadText>
		)
	}
	if (block.type === ContentBlockType.image) {
		return <>{ContentBlockType.image}</>
	}
	if (block.type === ContentBlockType.textWithImage) {
		return (
			<TextWithImage image={block.image} swap={index % 2 === 1}>
				<Wysiwyg>
					<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
				</Wysiwyg>
			</TextWithImage>
		)
	}
	if (block.type === ContentBlockType.html) {
		return (
			<Wysiwyg>
				<div dangerouslySetInnerHTML={{ __html: block.primaryText || '' }} />
			</Wysiwyg>
		)
	}
	if (block.type === ContentBlockType.gallery) {

		const items = (block.gallery?.items || [])
			.map((x : {image: ImageFragment}) => x.image)
			.filter(isDefined)
		return (
			<GalleryGrid
				items={items}
				viewQuestionOrCareerList={viewQuestionOrCareerList}
				viewStretch={block.stretch}
				noCrop={block.noCrop}
				noReveal={block.noReveal}
			/>
		)
	}
	if (block.type === ContentBlockType.productList) {
		return (
			<ProductList
				items={
					block.productList?.items
						.map((it) => it.product)
						.filter(isDefined) || []
				}
			/>
		)
	}
	if (block.type === ContentBlockType.documentList) {
		return (
			<Documents
				items={(block.documentList?.items || [])
					.map((x) => x.document)
					.filter(isDefined)}
			/>
		)
	}
	if (block.type === ContentBlockType.articleList) {
		return (
			<ArticleList
				items={(block.articleList?.items || [])
					.map((item) => item.article)
					.filter(isDefined)}
			/>
		)
	}
	if (block.type === ContentBlockType.categoryList) {
		return (
			<CategoryList
				items={(block.categoryList?.items || [])
					.map((item) => item.category)
					.filter(isDefined)}
			/>
		)
	}
	if (block.type === ContentBlockType.textColumns) {
		return <TextColumns items={block.columnList?.items || []} />
	}
	if (block.type === ContentBlockType.linkBoxes) {
		return (
			<LinkBoxes
				title={block.primaryText}
				items={block.columnList?.items || []}
			/>
		)
	}
	if (block.type === ContentBlockType.linkSlider) {
		return (
			<>
				<WidthBound>
					<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
					<div
						style={{
							textAlign: 'center',
							maxWidth: '520px',
							margin: '10px auto 20px',
						}}
					>
						<Wysiwyg>
							<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
						</Wysiwyg>
					</div>
				</WidthBound>
				<LinkSlider
					stretch={block.stretch}
					items={block.columnList?.items || []}
				/>
				<Spacer initial={90} />
			</>
		)
	}

	if (block.type === ContentBlockType.homepageTilesGrid) {
		const items = block.columnList?.items || []
		return (
			<>
				{items.length >= 4 && (
					<HomepageTilesGrid
						items={[
							<Garden title={block.primaryText}>
								<Wysiwyg>
									<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
								</Wysiwyg>
							</Garden>,
							...(items
								.slice(0, 4)
								.map(({ id, primaryText, image, buttonLink, useTitleBackground, titleAlignment } : ContentBlockColumnListItemFragment, i) => {
									return <CategoryImage
										key={id}
										noRadius
										FlexiLinkProps={buttonLink as FlexiLinkProps}
										image={image}
										title={primaryText || ''}
										mode={(i % 4 == 3 || i % 4 == 0) ? 'rect' : 'square'}
										useTitleBackground={useTitleBackground}
										titleAlignment={titleAlignment}
									/>
								}) as [
								HomepageTilesGridItem,
								HomepageTilesGridItem,
								HomepageTilesGridItem,
								HomepageTilesGridItem,
							]),
						]}
					/>
				)}
			</>
		)
	}
	if (block.type === ContentBlockType.homepageCategorySwiper) {
		return (
			<>
				<WidthBound>
					<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
					<div
						style={{
							textAlign: 'center',
							maxWidth: '520px',
							margin: '10px auto 20px',
						}}
					>
						<Wysiwyg>
							<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
						</Wysiwyg>
					</div>
				</WidthBound>
				<CategoriesSwiper
					items={
						block.categoryList?.items
							.map((it : {category: ContentCategoryListItemFragment}) => it.category)
							.filter(isDefined) || []
					}
				/>
				<Spacer initial={90} />
			</>
		)
	}
	if (block.type === ContentBlockType.homepageArticles) {
		return (
			<WidthBound>
				<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
				<ArticleList
					showLinkToBlog
					items={
						block.articleList?.items
							.map((it) => it.article)
							.filter(isDefined) || []
					}
				/>
				<Spacer initial={100} />
			</WidthBound>
		)
	}
	if (block.type === ContentBlockType.homepageTopCategories) {
		return (
			<Contained>
				<Spacer initial={30} />
				<WidthBound>
					<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
				</WidthBound>
				<TopProducts
					items={
						block.categoryList?.items
							.map(({ category }) => category)
							.filter(isDefined)
							.map((item : {
								tileImage: ImageFragment,
								id: string,
								link: {url: string} | null,
								locales: {
									name: string,
									lead: string,
									locale: {code: string}
								}[]
							}) => {
								const itemLocale = getLocale(item.locales, possibleLocales)
								return {
									title: itemLocale?.name || '',
									lead: itemLocale?.lead || '',
									picture: item.tileImage!,
									FlexiLinkProps: {
										nextLink: getProductCategoryLink(
											locale,
											item.id,
											item.link?.url || '',
										),
									},
								}
							}) || []
					}
				/>
				<Spacer initial={50} />
			</Contained>
		)
	}
	if (block.type === ContentBlockType.homepageNews) {
		return (
			<Contained>
				<WidthBound>
					<Spacer initial={25} />
					<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
					<News
						items={
							block.articleList?.items
								.map((it) => it.article)
								.filter(isDefined) || []
						}
						link={block.link}
					/>
				</WidthBound>
			</Contained>
		)
	}
	if (block.type === ContentBlockType.homepageInteractiveHero) {
		return (
			block.interactiveHero?.banners.length > 0 && (
				<>
					<InteractiveHero
						banners={block.interactiveHero.banners}
					/>
					<Spacer initial={-10} />
				</>
			)
		)
	}

	if (block.type === ContentBlockType.highlightedBox) {
		return (
			<HighlightedBox>
				<Wysiwyg>
					<div dangerouslySetInnerHTML={{ __html: block.html || '' }} />
				</Wysiwyg>
			</HighlightedBox>
		)
	}

	if (block.type === ContentBlockType.numberedColumns) {
		return (
			<NumberedColumns
				title={block.primaryText}
				items={block.columnList?.items || []}
			/>
		)
	}

	if (block.type === ContentBlockType.contactForm) {
		return (
			<ContactForm
				title={block.primaryText}
				note={
					block.html
				}
				allowFileInput={block.allowFileInput ?? true}
				requireFormFields={block.requireFormFields}
				formTargetEmail={block.formTargetEmail}
				requireCity={block.requireCity}
			/>
		)
	}

	if (block.type === ContentBlockType.videoArticle) {
		return block.videoList?.length > 0 && <VideoArticle video={block.videoList[0]} />
	}

	if(block.type === ContentBlockType.videoList)
	{
		return block.videoList?.length > 0 && <>
			<WidthBound>
				{ block.primaryText && <>
						<BarelyVisibleTitle>{block.primaryText}</BarelyVisibleTitle>
						<Spacer initial={25} />
				</>}
				<VideoTiles items={block.videoList} />
				<Spacer initial={100} />
			</WidthBound>
		</>
	}

	assertNever(block.type as never)
}

export const Content: React.FunctionComponent<ContentProps> = ({
	content,
	share,
	viewQuestionOrCareerList = false,
	viewInsideIntro = false,
	innerSize,
}) => {
	const locale = useCurrentLocale()
	const possibleLocales = usePossibleLocales()
	if (content) {
		return (
			<div
				className={cn('content', innerSize && `view-innerSize-${innerSize}`)}
			>
				{content.blocks.map((block, i) => {
					const stretch =
						block.type === ContentBlockType.homepageTilesGrid ||
						block.type === ContentBlockType.homepageCategorySwiper ||
						block.type === ContentBlockType.homepageArticles ||
						block.type === ContentBlockType.homepageTopCategories ||
						block.type === ContentBlockType.homepageNews ||
						block.type === ContentBlockType.productList ||
						block.type === ContentBlockType.categoryList ||
						block.type === ContentBlockType.homepageInteractiveHero ||
						block.type === ContentBlockType.videoList ||
						block.stretch === true

					return (
						<section
							className={cn(
								'content-block',
								`view-type-${block.type}`,
								stretch && 'view-stretch',
							)}
							key={i}
						>
							<ViewportWrap minHeight={300} forceShowHeight={300 * i}>
								{(renderBlock(
									block,
									locale,
									possibleLocales,
									viewQuestionOrCareerList,
									viewInsideIntro,
									i,
								) ?? <></>) as React.ReactElement}
							</ViewportWrap>
						</section>
					)
				})}
				{share && (
					<div className="content-share">
						<Share data={share} />
					</div>
				)}
			</div>
		)
	}
	return null
}
