import React from 'react'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { CategoryListItemLocaleFragment, ContentCategoryListItemFragment, LinkType } from '../src/model/fragments'
import { CategoryImage } from './CategoryImage'
import { Swiper } from './Swiper'

export interface CategoriesSwiperProps {
	items: ContentCategoryListItemFragment[]
}

export const CategoriesSwiper: React.FunctionComponent<CategoriesSwiperProps> =
	({ items }) => {
		const localization = useLocalization()

		return (
			<div className="categoriesSwiper">
				<Swiper
					items={items.map((item) => (
						<div className="categoriesSwiper-item" key={item.id}>
							<div className="categoriesSwiper-item-in">
								<CategoryImage
									FlexiLinkProps={{
										type: LinkType.internal,
										internalLink: item.link!,
									}}
									image={item.tileImage}
									mode={'square'}
									title={localization.extractLocale<CategoryListItemLocaleFragment>(item.locales)?.name || ''}
								/>	
							</div>
						</div>
					))}
				/>
			</div>
		)
	}
