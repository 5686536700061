import React from 'react'

export type HomepageTilesGridItem = React.ReactNode

export interface HomepageTilesGrid {
	items: [
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
	]
}

export const HomepageTilesGrid: React.FunctionComponent<HomepageTilesGrid> = ({
	items,
}) => {
	return (
		<div className="homepageTilesGrid">
			{items.map((item, i) => (
				<div className="homepageTilesGrid-item" key={i}>
					<div className="homepageTilesGrid-item-in">{item}</div>
				</div>
			))}
		</div>
	)
}
