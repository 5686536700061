import React from 'react'
import { dateToDMY } from '../scripts/utils'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { useTranslate } from '../src/hooks/useTranslate'
import {
	LinkFragment,
	ArticleFragment,
} from '../src/model/fragments'
import { getLocale, getPossibleLocales } from '../src/model/locale'
import { getBlogArticleLink } from '../src/model/urls'
import { Button } from './Button'
import { CardBox } from './CardBox'
import { CategoryTag } from './CategoryTag'
import { FlexiLink, FlexiLinkProps } from './FlexiLink'

export interface NewsProps {
	items: ArticleFragment[]
	link?: LinkFragment
}

export const News: React.FunctionComponent<NewsProps> = ({ items, link }) => {
	const locale = useCurrentLocale()
	const possibleLocales = getPossibleLocales(locale)
	const translate = useTranslate();

	return (
		<div className="news">
			<div className="news-items">
				{items.map((item, i) => { 
					const data = getLocale(item.locales, possibleLocales);
					return data && data.link && (
					<div className="news-item" key={i}>
						<CardBox
							FlexiLinkProps={{
								nextLink: getBlogArticleLink(locale, item.id, data.link!.url),
							}}
							hrefText={translate('read-more')}
							title={data.title}
							date={dateToDMY(item.publishedAt)}
							content={data.lead}
							categoryTag={
								item.topics.length > 0 ? (
									<>
										{item.topics.map((topic) => { 
											const tdata = getLocale(topic.locales, possibleLocales);
											return tdata && (
											<CategoryTag key={topic.id} text={tdata.title} />
										)})}
									</>
								) : undefined
							}
						/>
					</div>
				)})}
			</div>
			{link && (
				<div className="articleList-more">
					<FlexiLink {...(link as FlexiLinkProps)}>
						<a className="articleList-more-link">
							<Button arrow>{translate('go-to-news')}</Button>
						</a>
					</FlexiLink>
				</div>
			)}
		</div>
	)
}
