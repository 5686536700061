import React from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import { Checkbox } from './Checkbox'

export interface GdprDisclaimerProps {}

export const GdprDisclaimer: React.FunctionComponent<GdprDisclaimerProps> =
	({}) => {
		const translate = useTranslate();
		return (
			<Checkbox
				control={{ required: true }}
				label={translate('gdpr-label')}
				bland
			/>
		)
	}
