import React from 'react'
import { ImageFragment } from '../src/model/fragments'
import { Button, ButtonView } from './Button'
import { CardBox } from './CardBox'
import { FlexiLinkProps } from './FlexiLink'
import { Picture, PictureProps } from './Picture'

export interface TopProductProps {
	title: React.ReactNode
	image?: ImageFragment
	FlexiLinkProps: FlexiLinkProps
	lead: string
}

export const TopProduct: React.FunctionComponent<TopProductProps> = ({
	title,
	image,
	FlexiLinkProps,
	lead,
}) => {
	return (
		<div className="topProduct">
			<CardBox
				content={
					<>
						<h1 className="topProduct-title">{title}</h1>
						{image && (
							<div className="topProduct-picture">
								<Picture alt={lead} image={image} width={300} height={200} />
							</div>
						)}
						<div className="topProduct-description" dangerouslySetInnerHTML={{__html: lead}} />
					</>
				}
				footer={
					<div className="topProduct-action">
						<Button arrow view={ButtonView.outline}>
							Přejít do detailu
						</Button>
					</div>
				}
				FlexiLinkProps={FlexiLinkProps}
			/>
		</div>
	)
}
