import React from 'react'
import { ContentBlockColumnListItemFragment } from '../src/model/fragments'
import { Arrow } from './Arrow'

export interface TextColumnsProps {
	items: ContentBlockColumnListItemFragment[]
}

export const TextColumns: React.FunctionComponent<TextColumnsProps> = ({
	items,
}) => {
	return (
		<div className="textColumns">
			{items.map((item, i) => (
				<section key={i} className="textColumns-item">
					<h1 className="textColumns-title">
						{item.primaryText} <Arrow />
					</h1>
					<div dangerouslySetInnerHTML={{__html: item.html}}>

					</div>
				</section>
			))}
		</div>
	)
}
