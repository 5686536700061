import React from 'react'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { ContentBlockColumnListItemFragment } from '../src/model/fragments'
import { CardBox } from './CardBox'
import { FlexiLinkProps } from './FlexiLink'
import { Swiper } from './Swiper'

export interface LinkSliderProps {
	stretch: boolean
	items: ContentBlockColumnListItemFragment[]
}

export const LinkSlider: React.FunctionComponent<LinkSliderProps> =
	({ stretch, items }) => {
		const localization = useLocalization()

		return (
			<div className="linkSlider">
				<Swiper
				    loop={stretch}
					items={items.map((item) => (
						<div className="linkSlider-item" key={item.id}>
							<div className="linkSlider-item-in">
							<CardBox
								FlexiLinkProps={
									item.buttonText && item.linkType ?
										
											 (item.buttonLink as FlexiLinkProps)
										: undefined
								}
								hrefText={item.buttonText || undefined}
								heroImage={item.image ? item.image : undefined}
								title={item.primaryText}
								content={item.html || ''}
							/>
							</div>
						</div>
					))}
				/>
			</div>
		)
	}
