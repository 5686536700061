import {
	sendContactMessage,
	SendContactMessageRequest,
} from '../api/sendContactMessage'
import { useSubmitForm } from './useSubmitForm'

type FormValues = Omit<SendContactMessageRequest, 'type'>

export interface UseSendContactMessageFormArgs {
	type: SendContactMessageRequest['type']
	staticMessageContent?: () => string
	providedSubject: string | undefined
	providedEmail: string | undefined
	onSend?: () => void
}

export const useSendContactMessageForm = ({
	onSend,
	staticMessageContent,
	providedSubject,
	providedEmail,
	type,
}: UseSendContactMessageFormArgs) => {
	return useSubmitForm<FormValues>({
		onSend,
		send: (values) => sendContactMessage({ 
			type, 
			providedSubject, 
			providedEmail,
			staticMessageContent: staticMessageContent ? staticMessageContent() : '',
			 ...values }),
	})
}

