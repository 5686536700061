import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { UseFormMethods } from 'react-hook-form'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { getConfig } from '../src/utils/config'

export interface RecaptchaProps {
	register: UseFormMethods['register']
	setValue: UseFormMethods['setValue']
}

export const Recaptcha: React.FunctionComponent<RecaptchaProps> = ({
	register,
	setValue,
}) => {
	const locale = useCurrentLocale()

	React.useEffect(() => {
		register('recaptcha')
		setValue('recaptcha', '')
	}, [register, setValue])

	return (
		<ReCAPTCHA
			hl={locale}
			sitekey={getConfig('recaptchaSiteKey').recaptchaSiteKey}
			onChange={(token) => setValue('recaptcha', token || '')}
		/>
	)
}
