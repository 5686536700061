import * as runtypes from 'runtypes'

export type ApiFetchResponse<Data> =
	| { ok: false; error: string }
	| { ok: true; data: Data }

export const apiFetch = async <Data>(
	responseDataType: runtypes.Runtype<Data>,
	endpoint: string,
	data: any,
): Promise<ApiFetchResponse<Data>> => {

	const response = await fetch(endpoint, {
		body: JSON.stringify(data),
		method: 'POST',
		headers: {
			'Content-type': 'application/json',
		},
	})
	const json = await response.json()
	const responseType = runtypes.Union(
		runtypes.Record({
			ok: runtypes.Literal(false),
			error: runtypes.String,
		}),
		runtypes.Record({
			ok: runtypes.Literal(true),
			data: responseDataType,
		}),
	)
	return responseType.check(json)
}
