import React from 'react'
import { VideoFragment } from '../src/model/fragments'
import cn from 'classnames'
import { Card } from './Card'
import { getLocale } from '../src/model/locale'
import { usePossibleLocales } from '../src/hooks/usePossibleLocales'
import { useTranslate } from '../src/hooks/useTranslate'
import { CardBox } from './CardBox'
import SvgPrint from './icons/SvgPrint'
import SvgPlay from './icons/SvgPlay'
import { getPlaceLink, getVideoLink } from '../src/model/urls'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'

export interface VideoTilesProps {
	items: VideoFragment[]
	grid?: boolean
}

export const VideoTiles: React.FunctionComponent<VideoTilesProps> = (
	props,
) => {
	const locale = useCurrentLocale();
	const possibleLocales = usePossibleLocales();
	const translate = useTranslate();

	if(!props.items.length)
	{
		return <></>;
	}

	return (
		<div className={cn('videoTiles')}>
			{props.items.map((video) => {
				const data = getLocale(video.locales, possibleLocales);
				if(!data)
				{
					return <></>;
				}

				return <div className="videoTiles-item" key={video.id}>
					<CardBox
						FlexiLinkProps={data.link
						? {
								nextLink: getVideoLink(
									locale,
									video.id,
									data.link.url,
								),
							}
						: undefined}
						hrefText={translate('play-video')}
						title={'VIDEO: ' + data.title}
						content={''}
						footer={
							<span className='videoTiles-item-footer'>
								<SvgPlay/>
							</span>
						}
						heroImage={video.image}
					/>
				</div>
			})}
		</div>
	)
}
