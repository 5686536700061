import React from 'react'
import { Swiper } from './Swiper'
import { TopProduct, TopProductProps } from './TopProduct'

export interface TopProductsProps {
	items: TopProductProps[]
}

export const TopProducts: React.FunctionComponent<TopProductsProps> = ({
	items,
}) => {
	return (
		<div className="topProducts">
			<Swiper
				items={items.map((item, i) => (
					<div className="topProducts-item" key={i}>
						<div className="topProducts-item-in">
							<TopProduct {...item} />
						</div>
					</div>
				))}
			/>
		</div>
	)
}
