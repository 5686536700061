import React from 'react'
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper as SwiperLibrary, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export interface SwiperProps {
	items: React.ReactNode[]
	loop?: boolean
}

export const Swiper: React.FunctionComponent<SwiperProps> = ({ items, loop }) => {
	return (
		<div className="swiper">
			<SwiperLibrary
				slidesPerView={'auto'}
				navigation
				loop={ loop === undefined || loop }
				centerInsufficientSlides
				pagination={{ clickable: true  }}
				initialSlide={
					'matchMedia' in globalThis && matchMedia('(min-width: 1200px').matches
						? 1
						: 0
				}
			>
				{items.map((item, i) => (
					<SwiperSlide key={i}>{item}</SwiperSlide>
				))}
			</SwiperLibrary>
		</div>
	)
}
